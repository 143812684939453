.footer {
  color: white;
  background-color: #c56526;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  position: relative;
  bottom: 0;
  width: 100%;
}

.row {
  padding-left: 2em;
  padding-right: 2em;
}

a.policy {
  color: white;
}

a.policy:hover {
  color: white;
}
