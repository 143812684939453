body,
h1 {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

h1 {
  margin-bottom: 1rem;
}

em {
  background: cyan;
  font-style: normal;
}

.react-autosuggest__container {
  position: static;
  width: 98%;
  /* margin-left: auto; */
  /* margin-right: auto; */
}

.react-autosuggest__input {
  width: 98%;
  box-sizing: content-box;
  padding: 8px 8px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  z-index: 2;
  box-sizing: border-box;
  top: 51px;
  width: 100%;
  border: 1px solid #aaaaaa;
  background-color: #f8f9fa;
  /* font-family: Helvetica, sans-serif; */
  /* font-weight: 300; */
  /* font-size: 16px; */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* .button-bar {
  position: relative;
  z-index: 1 !important;
  width: 267px !important;
} */

.react-autosuggest__section-title {
  padding: 10px 20px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/*this is for each search suggestion*/
.react-autosuggest__suggestion {
  cursor: pointer;
  margin: 5px 5px;
  padding: 0px 5px;
  background-color: white;
}

table {
  border-collapse: separate !important;
  border-spacing: 5px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ffdfbf;
}

.card-header {
  text-align: center;
}

.card-title {
  text-align: center;
}

.card-text {
  text-align: center;
}

span.heading {
  color: #c56526;
}

a.dark {
  color: black;
}

h3 {
  color: #c56526;
}
.results {
  font-size: 16px !important;
}
.theme-link {
  color: #c56526;
  font-size: 28px !important;
}

.theme-link:hover {
  color: #c56526;
  -webkit-text-decoration-color: #c56526 !important;
          text-decoration-color: #c56526 !important;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  background-color: #f8f9fa;
}

.content {
  background-color: white;
}

.popular {
  /* min-height: 100vh; */
  background-color: #f8f9fa;
  padding: 0;
}

.content-wrap {
  flex: 1 1;
}

.box {
  border: 0.5px solid #d3d3d3;
  padding: 0px;
}
.box:hover {
  border: 1px solid #c56526;
}

.ais-Highlight-highlighted {
  color: #c56526;
  background-color: transparent;
  padding: 0px;
}

span.price-box {
  text-align: center;
}

.card-body {
  font-size: 14px;
}

.row-box {
  border: 0.5px solid #d3d3d3;
  padding: 0px;
}
a.product:hover {
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  color: black;
}

button.see-more {
  border: 1px solid #d3d3d3;
  /* text-decoration-color: transparent; */
}

button.see-more:hover {
  border: 1px solid #d3d3d3;
  background-color: transparent;
  text-decoration: underline;
}

.remove-all-margin {
  margin: 0 !important;
}

.navbar {
  border-bottom: 1px solid #d3d3d3;
}

button.navbar-toggler {
  border-style: none;
  padding: 0px;
}

/*this is for search buttons to be below search suggesstions*/
.button-bar {
  position: relative;
  z-index: 1 !important;
  width: 320px !important;
}
.genderButton {
  width: 95px !important;
}
.sizeButton {
  width: 80px !important;
}

.search-button {
  background-color: #c56526 !important;
  border-color: #c56526 !important;
  color: white !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  width: 145px !important;
  padding: 8px !important;
}

.filter-button {
  background-color: #c56526 !important;
  border-color: #c56526 !important;
  color: #f8f9fa !important;
  width: 110px;
  height: 40px;
}

.filter-button-old {
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
  color: #c56526 !important;
}

.alt-theme-button {
  background-color: white !important;
  /* border-color: #6c757d !important; */
  color: #c56526 !important;
}

.page-item {
  margin: 1px !important;
}

.theme-button {
  background-color: #c56526 !important;
  border-color: #c56526 !important;
  color: white !important;
  /* border-radius: 25px !important; */
}

.theme-button-filter {
  background-color: #c56526 !important;
  border-color: #c56526 !important;
  color: white !important;
}

.dropdown-toggle {
  background-color: #ffffff !important;
  border-color: #aaaaaa !important;
  color: black !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  /* width: 90px; */
}

.dropdown-item {
  background-color: #ffffff !important;
  border-color: #aaaaaa !important;
  color: black !important;
  font-weight: 300 !important;
  font-size: 16px !important;
}

.filterContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .box-title { // this is for shoe title length limiting
  max-width: 13ch;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
} */

.footer {
  color: white;
  background-color: #c56526;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  position: relative;
  bottom: 0;
  width: 100%;
}

.row {
  padding-left: 2em;
  padding-right: 2em;
}

a.policy {
  color: white;
}

a.policy:hover {
  color: white;
}

